<template>
  <div class="team_section team_upper" v-if="miembro">
    <b-container fluid class="team_container">
      <b-row class="team_wrap" align-v="center">
        <b-col lg="6" md="12" class="teamD_wrap">
          <hr />
        </b-col>
        <b-col lg="4" class="teamD_wrap bio_wrap d-block d-lg-none">
          <h2>{{ miembro.nombre }}</h2>
          <h3>{{ localeCode==='es' ? miembro.cargo : miembro.cargoIngles }}</h3>
        </b-col>
        <b-col lg="6" md="12" class="teamD_wrap">
          <b-list-group horizontal class="sociales">
            <b-list-group-item>
              <p>Follow:</p>
            </b-list-group-item>
            <b-list-group-item>
              <b-link
                v-if="miembro.redes_sociales.facebook"
                :href="miembro.redes_sociales.facebook"
                target="_blank"
                ><b-img
                  :src="require('@/assets/img/svg/ico-fb-color.svg')"
                ></b-img
              ></b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link
                v-if="miembro.redes_sociales.instagram"
                :href="miembro.redes_sociales.instagram"
                target="_blank"
                ><b-img
                  :src="require('@/assets/img/svg/ico-insta-color.svg')"
                ></b-img
              ></b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link
                v-if="miembro.redes_sociales.linkedin"
                :href="miembro.redes_sociales.linkedin"
                target="_blank"
                ><b-img
                  :src="require('@/assets/img/svg/ico-linked-color.svg')"
                ></b-img
              ></b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link
                v-if="miembro.redes_sociales.correo"
                :href="'mailito:'+miembro.redes_sociales.correo"
                target="_blank"
                ><b-img
                  :src="require('@/assets/img/svg/ico-email-color.svg')"
                ></b-img
              ></b-link>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="team_wrap" align-v="center">
        <b-col lg="4" class="teamD_wrap bio_wrap d-none d-lg-block">
          <h2>{{ miembro.nombre }}</h2>
          <h3>{{ localeCode==='es' ? miembro.cargo : miembro.cargoIngles }}</h3>
        </b-col>
        <b-col lg="8" class="teamD_wrap bio_wrap"> </b-col>
      </b-row>
      <b-row class="team_wrap">
        <b-col lg="4" class="teamD_wrap bio_wrap">
          <div class="img_wrap">
            <b-img
              v-if="miembro.imagen"
              :src="miembro.imagen.url"
              fluid
              alt="Responsive image"
            ></b-img>
          </div>
        </b-col>
        <b-col lg="8" class="teamD_wrap bio_wrap">
          <p v-html="localeCode==='es' ? miembro.descripcion_extendida : miembro.descripcion_extendidaIngles " />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  methods: {
    ...mapActions({
      getMiembro: "equipo/getMiembro",
    }),
  },
  computed: {
    ...mapGetters({
      miembro: "equipo/miembro",
      localeCode: "locale/localeCode",
    }),
  },
  async mounted() {
    await this.getMiembro(this.$route.params.id);
  },
};
</script>

<style scoped>
.team_section {
  padding-top: 200px;
  padding-bottom: 130px;
}
.team_container .team_wrap hr {
  border-color: var(--primario);
}

.team_container .team_wrap .bio_wrap h2 {
  font-size: 50px;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.05px;
  color: var(--texto);
}
.team_container .team_wrap .bio_wrap h3 {
  font-size: 21px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.63px;
}
.team_container .team_wrap .bio_wrap p {
  font-size: 29px;
  font-weight: 300;
  line-height: 1.52;
  color: var(--texto);
}
.team_container .team_wrap .bio_wrap .img_wrap {
  position: relative;
  padding-top: 85%;
  margin: 0 15% 0 0;
}
.team_container .team_wrap .bio_wrap .img_wrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.team_container .team_wrap .sociales {
  justify-content: flex-end;
  align-items: center;
}
.team_container .team_wrap .sociales p {
  font-size: 20px;
  line-height: 2.2;
  font-style: italic;
  margin-bottom: 0;
}
.team_container .team_wrap .sociales .list-group-item {
  border: none;
  line-height: 1;
}
.team_container .team_wrap .sociales img {
  width: 33px;
  height: 33px;
  transition: 0.3s ease-out all;
}
.team_container .team_wrap .sociales img:hover {
  transform: scale(1.2);
}
@media (max-width: 992px) {
  .team_container .team_wrap {
    justify-content: center;
  }
  .team_container .team_wrap .teamD_wrap {
    padding: 0 65px;
  }
  .team_container .team_wrap .bio_wrap .img_wrap {
    padding-top: 70%;
    margin: 0 15%;
    width: 70%;
  }
}
@media (max-width: 576px) {
  .team_container .team_wrap .bio_wrap h2 {
    font-size: 30px;
    line-height: 1.1;
  }
  .team_container .team_wrap .bio_wrap p {
    font-size: 16px;
    line-break: 1.2;
  }
  .team_container .team_wrap .teamD_wrap {
    padding: 0 25px;
  }
  .team_container .team_wrap .sociales .list-group-item {
    padding: 5px 8px;
  }
  .team_container .team_wrap .sociales .list-group-item:first-child {
    display: none;
  }
  .team_container .team_wrap .sociales img {
    width: 20px;
    height: 20px;
  }
}
</style>
